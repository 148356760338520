import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";

import { useAppSelector } from "~/store";
import { Loading } from "~data-display/Loading";
import { pagesPath } from "~generated/$path";

const Home: NextPage = () => {
  const user = useAppSelector((state) => state.auth.user);
  const router = useRouter();

  React.useEffect(() => {
    if (!user) return;

    router.replace(user.admin ? pagesPath.admin.$url() : pagesPath.client.$url());
  }, [router, user]);

  return <Loading />;
};

export default Home;
